import { MpcSettingsModule } from "./helpers/MpcSettings";
import { NlVanillajax, INlVanillajaxParams, ContentType } from "./helpers/vanillajax";
import { ErrorMessage } from "../../Models/ErrorMessage.generated"

declare const window: Window;

export module MPCErrorHandlerModule {
    import MpcSettings = MpcSettingsModule.MpcSettings;

    export class ErrorHandlerManager {
        private static _instance: ErrorHandlerManager;
        private static _errorCount: number = 0;
        private _settings: MpcSettings;

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        constructor() {
            this.Init();
        }

        public Init(): void {
            this._settings = MpcSettings.instance;
            window.onerror = (msg, url, line, col, error) => {
                ErrorHandlerManager._errorCount++;
                if (ErrorHandlerManager._errorCount > 10) {
                    return true;
                }

                const string = msg.toString().toLowerCase();
                const substring = "script error";
                if (string.indexOf(substring) === 0) {
                    console.warn("cannot handle JS error. probably CORS issue.");
                    return false;
                }

                if (typeof (msg) !== "string") {
                    let errorEvent: ErrorEvent = <ErrorEvent>(msg);
                    if (errorEvent.error) {
                        msg = errorEvent.error.message + "\n"+ errorEvent.error.stack;
                        line = errorEvent.lineno;
                        col = errorEvent.colno;
                        url = errorEvent.filename;
                    } else {
                        msg = JSON.stringify(msg);
                    }
                }
                //log error to service
                let errStr: string = undefined;
                if(error)
                    errStr = JSON.stringify(error);
                const errorMsg = <ErrorMessage>{
                    message: msg,
                    url: url,
                    line: line,
                    col: col,
                    error: errStr
                };
                this.LogError(errorMsg);

                // for development, we want to see errors in console 
                const suppressDefaultErrorHandlerAkaConsolePrint = !this._settings.isDevelopment;
                
                return suppressDefaultErrorHandlerAkaConsolePrint;
            };
        }

        public LogError(error: ErrorMessage): Promise<void> {
            // handle only mpc errors:

            // source off error has to be known, if there is bundle/ file url, skip this logging:
            if (!error.url)
                return;

            // asset url has to contain mpc scope name
            if(error.url.indexOf('manualproductcomparison') === -1)
                return;
            
            // MPC is made on pure html and ts, we are not responsible for any react or other frameworks:
            if(error.message.indexOf('ReactDOM') > 0)
                return;
            
            // read local storage and stringify it. This will help to solve problems and get better feeling what data was used on exception:
            const mpcLocalStorageJson = window.localStorage.getItem(MpcSettings.instance.mpcLocalStorageKey);
            if (mpcLocalStorageJson) {
                error.mpcLocalStorage = mpcLocalStorageJson;
            }
            else {
                error.mpcLocalStorage = 'local storage was not found';
            }

            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            params.url = MpcSettingsModule.MpcSettings.instance.apiUrl +
                'Debug/LogJavascriptError';
            params.data = JSON.stringify(error);

            return new NlVanillajax(params).postWithPromise<void>()
                .then(() => {
                })
                .catch((reason) => {
                    console.log("error could not be logged to service. reason: "+reason.status+" "+reason.statusText);
                });

        }
    }
}

MPCErrorHandlerModule.ErrorHandlerManager.instance.Init();