import { MPCLocalStorageService } from "../local-storage-service";
import {MPCAddElementContext} from "../typings/addElementContext";
import {MPCGetProductInfoByCompareLinkContext} from '../typings/GetProductInfoByCompareLinkContext';
import {NlVanillajax, INlVanillajaxParams} from "../helpers/vanillajax";
import {MPCINavigationService} from "../typings/iNavigationService";
import {MPCIAbstractContent} from "../typings/iAbstractContent";
import { MPCIFlyoutContentController} from "../typings/iFlyoutContentController";
import {MPCEventBus} from "../helpers/event-bus";
import { MpcSettingsModule } from "../helpers/MpcSettings";
import { ProductComparisonModel, Product } from "../../../../DomainObjects/Dtos/Product.generated";
const RecycleBinIcon = require("../../images/icon_remove.svg") as string;
const PlusIcon = require("../../images/plus-2021.svg") as string;
import { MPCErrorHandlerModule } from "../errorhandler";
import { ErrorMessage } from "../../../Models/ErrorMessage.generated";

export module MPCFlyoutContent {
    import ProductComparisonModelManager = MPCLocalStorageService.ProductComparisonModelManager;
    import AddElementContext = MPCAddElementContext.AddElementContext;
    import GetProductInfoByCompareLinkContext = MPCGetProductInfoByCompareLinkContext.GetProductInfoByCompareLinkContext;
    import INavigationService = MPCINavigationService.INavigationService;
    import IAbstractContent = MPCIAbstractContent.IAbstractContent;
    import IFlyoutContentController = MPCIFlyoutContentController.IFlyoutContentController;
    import EventBus = MPCEventBus.EventBus;
    import MpcSettings = MpcSettingsModule.MpcSettings;
    import ErrorHandlerManager = MPCErrorHandlerModule.ErrorHandlerManager;

    export class FlyoutContent implements IAbstractContent {
        private productComparisonModelManager: ProductComparisonModelManager;
        private eventBus: EventBus;
        private listContainersArray: Array<HTMLElement>;
        private flyoutContent: HTMLElement;
        private navigationService: INavigationService;
        private oldListContainer: HTMLElement;
        private iFlyoutContentController: IFlyoutContentController;
        private emptyProductSlotTitle: string;
        private clearFlyoutCategoryBtnTitle: string;
        private activeCategoryTabName: string;
        private closeFlyoutBtn: Element;

        init(flyout: Element, navigationService: INavigationService, iFlyoutContentController: IFlyoutContentController, closeFlyoutBtn: Element): void {
            this.productComparisonModelManager = ProductComparisonModelManager.instance;
            this.eventBus = MPCEventBus.EventBus.instance;
            this.flyoutContent = flyout.querySelector('.flyout-content');
            this.emptyProductSlotTitle = this.flyoutContent.getAttribute("data-EmptyProductSlotTitle");
            this.clearFlyoutCategoryBtnTitle = this.flyoutContent.getAttribute("data-ClearFlyoutCategoryBtnTitle");
            this.listContainersArray = new Array<HTMLElement>();
            this.navigationService = navigationService;
            this.iFlyoutContentController = iFlyoutContentController;
            this.closeFlyoutBtn = closeFlyoutBtn;
        }

        private clearCategoryProducts(listContainer: HTMLElement): void {
            const itemsContainer: HTMLElement = listContainer.querySelector('.items-container');
            const listItems: NodeListOf<HTMLElement> = itemsContainer.querySelectorAll('.list-item:not(.empty-list-item)');
            const listItemsArray: Array<HTMLElement> = Array.prototype.slice.call(listItems);
            
            for (const listItem of listItemsArray) {
                this.removeProduct(listContainer, listItem, true);
            }

            const categoryName: string = this.getListContainerCategoryName(listContainer);
            this.productComparisonModelManager.removeAllProductsOfCategory(categoryName);
        }

        public refresh(model: ProductComparisonModel): void {
            if(model && model.products && model.products.length) {
                for (const newProduct of model.products) {
                    this.addProductToFlyoutContent(newProduct);
                }
                if (!model.activeNavigationTab) {
                    this.navigationService.markActiveNavigationTab(model.products[0].categoryName);
                }
            }
            else {
                if(this.listContainersArray) {
                    for (const listContainer of this.listContainersArray) {
                        this.clearCategoryProducts(listContainer);
                    }
                }
            }
        }
        
        public addProduct(savKey: string, seoSlug: string, origin: string): Promise<Product> {
            const context: AddElementContext = new class implements AddElementContext {
                salesArticleVariantKey: string;
                originSeoSlug: string;
                origin: string;
            };
            context.salesArticleVariantKey = savKey;
            context.originSeoSlug = seoSlug;
            context.origin = origin;

            // Call controller method, via ajax, and get image and text info from MPC
            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            params.url = MpcSettingsModule.MpcSettings.instance.apiUrl +"Products/GetProductInfo";
            params.data = JSON.stringify(context);

            let nlVanillajax = new NlVanillajax(params);
            let postWithPromise = nlVanillajax.postWithPromise<Product>();
            postWithPromise.then((productDataFromService: Product) => {
                    this.handleProductInfoToAdd(productDataFromService);
                    return productDataFromService;
                })
                .catch((err) => {
                    //TODO user notification
                    //console.error('could not retrieve product info', err.statusText);
                });
             return postWithPromise;
        }
        
        public addProductByMasterArticleNo(maNo: number, colorCode: number, origin: string): Promise<any> {
            const context = {} as GetProductInfoByCompareLinkContext;
            context.masterArticleNo = maNo;
            context.colorCode = colorCode;
            context.origin = origin;

            // Call controller method, via ajax, and get image and text info from MPC
            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            params.url = MpcSettingsModule.MpcSettings.instance.apiUrl +"Products/GetProductInfoByCompareLink";
            params.data = JSON.stringify(context);

            return new NlVanillajax(params).postWithPromise()
                .then((productDataFromService: Product) => {
                    this.handleProductInfoToAdd(productDataFromService);
                    return productDataFromService;
                })
                .catch((err) => {
                    ErrorHandlerManager.instance.LogError(<ErrorMessage>{
                        message: "error getting product info because: " +
                            err +
                            ", masterArticleNo: " +
                            maNo
                    }); 
                });
        }

        private handleProductInfoToAdd(productDataFromService: Product) {
            // Handle the response and add article or show notification for customer
            let productAdded: MPCLocalStorageService.AddResult;

            if (productDataFromService) {
                productAdded = this.productComparisonModelManager.addProduct(productDataFromService);
                if (productAdded === MPCLocalStorageService.AddResult.Added) {
                    this.addProductToFlyoutContent(productDataFromService);

                    // notify touch points that products are added
                    this.eventBus.publish("flyout.product-added", productDataFromService.articleId.salesArticleVariantKey);
                } else if (productAdded === MPCLocalStorageService.AddResult.Replaced) {
                    this.changeProductVariantInFlyoutContent(productDataFromService);
                    this.eventBus.publish("flyout.product-replaced", productDataFromService.articleId.salesArticleVariantKey);
                }
                if (productAdded !== MPCLocalStorageService.AddResult.RejectedCapacity) {
                    this.setActiveNavigationTab(productDataFromService.categoryName);
                }
            }
        }

        private removeProduct(listContainer: HTMLElement, listItem: HTMLElement, onlyDom: boolean = false): void {
            // current element will be removed from DOM, so we need to move to next one:
            if (window.shell.tabNav !== undefined)
                window.shell.tabNav.focusNext();

            const categoryName: string = this.getListContainerCategoryName(listContainer);
            const savKey: string = listItem.getAttribute('data-pcf-sav-key');
            const categoryUrl: string = listContainer.getAttribute('data-pcf-categoryUrl');
            const itemsContainer: HTMLElement = listContainer.querySelector('.items-container');

            let productRemoved: boolean = true;
            if (!onlyDom) {
                // call remove method on service:
                productRemoved = this.productComparisonModelManager.removeProduct(categoryName, savKey) != null;
            }

            if (productRemoved) {
                // store order number before item will be removed from list:
                const tabOrder = listItem.getAttribute('order');

                // remove product from flyout:
                itemsContainer.removeChild(listItem);
                itemsContainer.appendChild(this.createEmptyListItem(categoryUrl, tabOrder));

                // update flyout header
                this.navigationService.createOrUpdateNavigationTab(categoryName, -1, null, null);
                this.iFlyoutContentController.updateProductsCounter(-1);

                // notify touch points and flyout footer
                this.eventBus.publish("flyout.product-removed", savKey);
            }
        }

        private changeProductVariantInFlyoutContent(newProduct: Product): void {
            let categoryName: string;
            for (const listContainer of this.listContainersArray) {
                categoryName = this.getListContainerCategoryName(listContainer);

                if (categoryName == newProduct.categoryName) {
                    for (const item of this.getContainerListItems(listContainer)) {
                        if (item.getAttribute("data-pcf-masterArticleNo") === newProduct.articleId.masterArticleNo.toString()) {
                            item.setAttribute('data-pcf-sav-key', newProduct.articleId.salesArticleVariantKey);
                            item.setAttribute('href', newProduct.detailsPageUrl);
                            item.querySelector('.image-slot').setAttribute('src', newProduct.mainImage);
                            item.querySelector('.image-slot').setAttribute('alt', newProduct.altTag);
                        }
                    }
                    break;
                }
            }
        }

        private addProductToFlyoutContent(newProduct: Product): void {
            let currentListContainer: HTMLElement;
            let listContainerNotExists: boolean = true;
            let categoryName: string;

            // look for "list-container" with same categoryName or create new one
            for (const listContainer of this.listContainersArray) {
                categoryName = this.getListContainerCategoryName(listContainer);
            
                if(categoryName === newProduct.categoryName)
                {
                    this.createListItem(listContainer, newProduct);
                    currentListContainer = listContainer;
                    listContainerNotExists = false;
                    break;
                }
                else {
                    listContainerNotExists = true;
                }
            }

            if (listContainerNotExists) {
                let newListContainer: HTMLElement = this.createListContainer(newProduct);
                this.createListItem(newListContainer, newProduct);
                this.listContainersArray.push(newListContainer);
                this.flyoutContent.appendChild(newListContainer);
                currentListContainer = newListContainer;
            }
            
            // hide old list container:
            if (this.oldListContainer) {
                this.oldListContainer.style.display = 'none';
            }

            currentListContainer.style.display = 'flex';
            this.oldListContainer = currentListContainer;

            // update navigation tab:
            this.navigationService.createOrUpdateNavigationTab(newProduct.categoryName, 1, newProduct.navigationOrder, newProduct.articleId.salesArticleVariantKey);
            this.iFlyoutContentController.updateProductsCounter(1);
        }
        
        private createListContainer(newProduct: Product): HTMLElement {
            const newListContainer: HTMLElement = document.createElement('div');
            const newClearListBtn: HTMLElement = document.createElement('div');
            const newItemsContainer: HTMLElement = document.createElement('div');
            const btnId = 'cl_' + newProduct.categoryName;

            newItemsContainer.className = 'items-container';
            
            newClearListBtn.className = 'clear-list-btn';
            newClearListBtn.setAttribute('focusable', '');
            newClearListBtn.setAttribute('order', '0');

            newClearListBtn.innerHTML = this.clearFlyoutCategoryBtnTitle;
            newClearListBtn.id = btnId;
            newListContainer.className = 'list-container';
            newListContainer.setAttribute('data-pcf-categoryName', newProduct.categoryName);
            newListContainer.setAttribute('data-pcf-categoryUrl', newProduct.categoryUrl);
            newListContainer.setAttribute('group', '');

            newListContainer.appendChild(newClearListBtn);

            // create empty list items:
            for (let i: number = 0; i < MpcSettings.instance.maxProducts; i++) {
                newItemsContainer.appendChild(
                    this.createEmptyListItem(newProduct.categoryUrl, i.toString()));
            }

            newClearListBtn.addEventListener('click', () => { this.clearCategoryProducts(newListContainer) });
            newListContainer.appendChild(newItemsContainer);

            if (window.shell.tabNav !== undefined)
                window.shell.tabNav.onActivated('#' + btnId, 
                    () => { window.shell.tabNav.focus(this.closeFlyoutBtn); });
            
            return newListContainer;
        }
        
        private createEmptyListItem(categoryUrl: string, index: string): HTMLElement {
            const newListItem: HTMLElement = document.createElement('a');
            const newProductImageHolder = document.createElement('div');
            const newRemoveProductButton = document.createElement('div');
            const newProductDescription = document.createElement('div');
            const plusIcon: HTMLElement = document.createElement('div');

            plusIcon.setAttribute('class', 'mpc-plus');
            plusIcon.innerHTML = PlusIcon;

            newProductDescription.innerHTML = this.emptyProductSlotTitle;
            newProductDescription.className = 'description-slot';
            newRemoveProductButton.className = 'remove-item-container';
            newProductImageHolder.className = 'image-holder';
            newListItem.setAttribute("href", categoryUrl);
            newListItem.className = 'list-item empty-list-item';
            newListItem.setAttribute('focusable', '');

            newProductImageHolder.appendChild(plusIcon);
            newListItem.appendChild(newProductImageHolder);
            newListItem.appendChild(newRemoveProductButton);
            newListItem.appendChild(newProductDescription);
            
            return newListItem;
        }
        
        private createListItem(listContainer: HTMLElement, newProduct: Product): void {
            const itemsContainer: HTMLElement = listContainer.querySelector('.items-container');
            const newListItem: HTMLElement = document.createElement('a');
            const newProductImageHolder = document.createElement('div');
            const newProductImageSlot = document.createElement('img');
            const newRemoveProductButton: HTMLElement = document.createElement('div');
            const newProductDescription = document.createElement('div');
            const itemOriginDetailsPageUrl: string = newProduct.detailsPageUrl.indexOf('?') !== -1 ?
                newProduct.detailsPageUrl + '&itemorigin=productcomparison_flyout' :
                newProduct.detailsPageUrl + '?itemorigin=productcomparison_flyout';

            newProductDescription.innerHTML = newProduct.title;
            newProductDescription.className = 'description-slot';

            newRemoveProductButton.setAttribute('class', 'mpc-recycle');
            newRemoveProductButton.setAttribute('focusable', '');
            newRemoveProductButton.setAttribute('order', '0');
            newRemoveProductButton.addEventListener('click', (event: MouseEvent) => {
                this.removeProduct(listContainer, newListItem, false);
                event.stopPropagation();
                event.preventDefault();
            });
            newRemoveProductButton.innerHTML = RecycleBinIcon;
            
            newProductImageSlot.className = 'image-slot';
            newProductImageSlot.setAttribute('src', newProduct.mainImage);
            newProductImageSlot.setAttribute('alt', newProduct.altTag);
            
            newProductImageHolder.className = 'image-holder';
            newProductImageHolder.setAttribute('focusable', '');
            newProductImageHolder.setAttribute('order', '1');
            
            newListItem.setAttribute("href", itemOriginDetailsPageUrl);

            newListItem.setAttribute("data-pcf-masterArticleNo", newProduct.articleId.masterArticleNo.toString());
            newListItem.className = 'list-item';
            newListItem.setAttribute('data-pcf-sav-key', newProduct.articleId.salesArticleVariantKey);
            newListItem.setAttribute('group', '');

            newProductImageHolder.appendChild(newProductImageSlot);
            newListItem.appendChild(newProductImageHolder);
            newListItem.appendChild(newRemoveProductButton);
            newListItem.appendChild(newProductDescription);
            
            // remove empty list item:
            const emptyListItem: HTMLElement = itemsContainer.querySelector('.empty-list-item');

            if (!emptyListItem)
                return;

            let nextEmpty = emptyListItem.nextElementSibling;
            itemsContainer.removeChild(emptyListItem);

            // add new list item
            if (nextEmpty) {
                itemsContainer.insertBefore(newListItem, nextEmpty);
            } else {
                itemsContainer.appendChild(newListItem);
            }
        }
        
        private getListContainerCategoryName(listContainer: HTMLElement): string {
            return listContainer.getAttribute('data-pcf-categoryName');
        }
        
        public removeEmptyContainerList(): void {
            // remove all empty list containers
            if (!this.listContainersArray || this.listContainersArray.length <= 0)
                return;

            let removableContainers: Array<HTMLElement> = new Array<HTMLElement>();
            let selectFirstTab: boolean = false;

            for (const listContainer of this.listContainersArray) {
                const itemsContainer: HTMLElement = listContainer.querySelector('.items-container');
                const listItems: NodeListOf<HTMLElement> = itemsContainer.querySelectorAll('.empty-list-item');

                if (listItems.length == MpcSettings.instance.maxProducts) {
                    removableContainers.push(listContainer);
                }
            }

            for (const tmpContainer of removableContainers) {
                this.flyoutContent.removeChild(tmpContainer);
                this.listContainersArray.splice(this.listContainersArray.indexOf(tmpContainer), 1);

                // check does the active navigation tab will be removed like empty one:
                const emptyTabCatName: string = this.getListContainerCategoryName(tmpContainer);

                if (emptyTabCatName == this.activeCategoryTabName)
                    selectFirstTab = true;
            }
                
            if (this.listContainersArray.length > 0) {
                if (selectFirstTab) {
                    const selectedCategoryName: string = this.getListContainerCategoryName(this.listContainersArray[0]);
                    this.setActiveNavigationTab(selectedCategoryName);
                }
                else {
                    this.setActiveNavigationTab(this.activeCategoryTabName);
                }
            }
        }

        public updateContainerListVisibility(activeCategoryTabName: string): void {
            for (const listContainer of this.listContainersArray) {
                const tmpContainerCategoryName: string = this.getListContainerCategoryName(listContainer);
                
                if (tmpContainerCategoryName === activeCategoryTabName) {
                    listContainer.style.display = 'flex';
                    this.oldListContainer = listContainer;
                } else {
                    listContainer.style.display = 'none';
                }
            }

            // write it to the localStorage:
            this.activeCategoryTabName = activeCategoryTabName;
            this.productComparisonModelManager.setActiveNavigationTab(activeCategoryTabName);
        }

        public isFlyoutContentEmpty(): boolean {
            return this.listContainersArray == null 
                || this.listContainersArray == undefined 
                || this.listContainersArray.length == 0;
        }

        private getContainerListItems(listContainer: HTMLElement): Array<HTMLElement> {
            const listItems: NodeListOf<HTMLElement> = listContainer.querySelector('.items-container')
                .querySelectorAll('.list-item');
            return Array.prototype.slice.call(listItems);
        }

        public removeProductTP(salesArticleVariantKey: string): void {
            for (const listContainer of this.listContainersArray) {
                const listItemsArray: Array<HTMLElement> = this.getContainerListItems(listContainer);

                for (const listItem of listItemsArray) {
                    const savKey: string = listItem.getAttribute('data-pcf-sav-key');

                    if (savKey === salesArticleVariantKey) {
                        this.removeProduct(listContainer, listItem, false);
                        break;
                    }
                }
            }
        }

        setActiveNavigationTab(activeNavigationTab: string): boolean {
            const newTabActivated: boolean = this.navigationService.markActiveNavigationTab(activeNavigationTab);
            
            if(newTabActivated)
                this.updateContainerListVisibility(activeNavigationTab);

            return newTabActivated;
        }
    }
}