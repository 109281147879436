import { MPCITouchPoint } from "../typings/TouchPoint";
import { MPCCallLimiter } from "../helpers/call-limiter";
import { NlVanillajax, INlVanillajaxParams, ContentType, HtmlResult } from "../helpers/vanillajax";
import { MPCIFlyoutContentController } from "../typings/iFlyoutContentController";
import { MPCFlyoutContentController } from "../PCF/flyout-content-controller";
import { IResponseData } from "../typings/VariantSelection";
import { MpcSettingsModule } from "../helpers/MpcSettings";
const ScaleIcon = require("../../images/scale-2021.svg") as string;

export module MPCCompareButton {
    import ITouchPoint = MPCITouchPoint.ITouchPoint;
    import TouchPointContext = MPCITouchPoint.TouchPointContext;

    export class CompareButton implements ITouchPoint {
        private markerElement: HTMLElement;
        private parentContainer: HTMLElement;
        private touchPointElement: HTMLElement;
        private slug: string;
        private origin: string;
        private salesArticleVariantKey: string;
        private displayedInCategoryNavKey: string;
        private iFlyoutContentController: MPCIFlyoutContentController.IFlyoutContentController;        

        constructor(marker: HTMLElement, salesArticleVariantKey: string, slug: string, origin: string, displayedInCategoryNavKey: string,
            parentContainer: HTMLElement) {
            this.iFlyoutContentController = MPCFlyoutContentController.FlyoutContentController.instance;
            this.parentContainer = parentContainer;
            this.markerElement = marker;
            this.salesArticleVariantKey = salesArticleVariantKey;
            this.slug = slug;
            this.origin = origin;
            this.displayedInCategoryNavKey = displayedInCategoryNavKey;
            
            // only for new details page:
            if(this.parentContainer)
                this.initializeNew();
            else
                this.getTouchPoint();

            // Subscribe to new details page and update necessary changed variant info:
            if (typeof window !== 'undefined' && window.shell) {
                window.shell.subscribeTo(
                    'ESPP.DetailPage.PageUpdate',
                    (response: IResponseData) => {
                        this.updateSaveKeyFromOutsideTheScope(
                            response.orderModule.articleInfo.salesArticleVariantKey);
                    },
                    'ESCID.ESPP.ManualProductComparison');
            }

            if (typeof window !== 'undefined' && window.shell) {
                window.shell.subscribeTo(
                    'ESPP.OrderModal.Update',
                    ({ response }: { response: IResponseData } ) => {
                        this.updateSaveKeyFromOutsideTheScope(
                            response.orderModule.articleInfo.salesArticleVariantKey);
                    },
                    'ESCID.ESPP.ManualProductComparison');
            }
        }

        private updateSaveKeyFromOutsideTheScope(savKey: string): void {
            this.salesArticleVariantKey = savKey;
        }

        update(oldSavKey: string, newSavKey: string): void {
            return;
        }

        private getTouchPoint(): void {
            const context: TouchPointContext = new class implements TouchPointContext {
                SeoSlug: string;
            };
            context.SeoSlug = this.slug;

            let params: INlVanillajaxParams = <INlVanillajaxParams>{};
            const subPath = this.markerElement.classList.contains("scope-om-new")
                ? "TouchPoint/ForOrderModalNew/"
                : "TouchPoint/ForDetailsPage/";
            params.url = MpcSettingsModule.MpcSettings.instance.apiUrl + subPath;
            params.url += "?context.SeoSlug=" + this.slug;
            params.url += "&context.SavKey=" + this.salesArticleVariantKey;
            params.url += "&context.ItemOrigin=" + this.origin;
            if(this.displayedInCategoryNavKey)
                params.url += "&context.DisplayedInCategoryNavKey=" + this.displayedInCategoryNavKey;
            params.contentType = ContentType.Plain;
            new NlVanillajax(params).getWithPromise()
                .then((response: HtmlResult) => {
                    let container: HTMLElement = document.createElement('div');
                    container.innerHTML = response.Html;
                    this.touchPointElement = container.querySelector(".touchpoint");  //there might be text nodes in there, so not childNodes[0]
                    if (!this.touchPointElement) {
                        throw new Error("no touchpoint returned from service");
                    }                    
                    this.removePreviousButton();
                    this.markerElement.parentNode.replaceChild(this.touchPointElement, this.markerElement);
                    this.initialize();
                })
                .catch(() => {
                    //empty response indicates this article has disabled comparing for some reason
                    if (this.markerElement) {
                        this.markerElement.remove();
                    } else if (this.touchPointElement) {
                        this.touchPointElement.remove();
                    }
                });
        }

        private removePreviousButton(): void {
            const previousButton: HTMLElement =
                this.markerElement.parentElement.querySelector('.compare-button-wrapper');

            if (previousButton)
                previousButton.remove();
        }


        private initialize(): void {
            this.touchPointElement.querySelector('.comparison-icon').innerHTML = ScaleIcon;
            this.touchPointElement.addEventListener('click', (event: Event) => {
                event.preventDefault();
            });
            this.touchPointElement.addEventListener('click', MPCCallLimiter.CallLimiter.debounce(() => {
                this.iFlyoutContentController.addProduct(this.salesArticleVariantKey, this.slug, this.origin);
            }, 1000, true).bind(this));
        }

        // only for new details page:
        private initializeNew(): void {
            this.parentContainer.querySelector('.comparison-icon').innerHTML = ScaleIcon;
            this.parentContainer.addEventListener('click', (event: Event) => {
                event.preventDefault();
            });
            this.parentContainer.addEventListener('click', MPCCallLimiter.CallLimiter.debounce(() => {
                this.iFlyoutContentController.addProduct(this.salesArticleVariantKey, this.slug, this.origin);
            }, 1000, true).bind(this));
        }
    }
}